import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Img from "gatsby-image"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Welcome to the Sharpen Up! Blog" />
    <div className="w-full bg-color-secondary h-36 flex items-center justify-center flex-col">
      <h2 className="font-molot  text-color-primary text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center">
        Welcome to sharpenup.io
      </h2>
      <h4 className="font-display text-white text-xl text-center">
        Ready to become awesome at programming? Let's go!
      </h4>
    </div>
    <div className="w-full p-5 max-w-4xl mx-auto">
      <div className="py-6 px-5">
        <div>
          <h3 className="text-2xl text-left">Latest post</h3>
        </div>
        {data.latest.edges.map(({ node }) => {
          let featuredImgFluid = node.frontmatter.featuredImage
            ? node.frontmatter.featuredImage.childImageSharp.fluid
            : null

          return (
            <div key={node.id}>
              <Link className="text-color-secondary" to={node.frontmatter.slug}>
                <h4 className="my-2 text-lg text-color-primary font-semibold">
                  {node.frontmatter.title} ({node.frontmatter.date})
                </h4>
                {featuredImgFluid ? <Img fluid={featuredImgFluid} /> : null}
              </Link>
              <p className="mt-4 ">
                {node.frontmatter.description}&nbsp;
                <Link to={node.frontmatter.slug}>
                  <span className="text-color-primary my-1">Read more</span>
                </Link>
              </p>
            </div>
          )
        })}
      </div>
      <div className="py-6 px-5">
        <div>
          <h3 className="text-2xl text-left">More posts</h3>
        </div>
        {data.all.edges.map(({ node }) => (
          <div key={node.id} className="py-2">
            <Link className="text-color-secondary" to={node.frontmatter.slug}>
              <h4 className="my-2 text-lg text-color-primary font-semibold">
                {node.frontmatter.title} ({node.frontmatter.date})
              </h4>
            </Link>
            <p>
              {node.frontmatter.description}&nbsp;
              <Link to={node.frontmatter.slug}>
                <span className="text-color-primary my-1">Read more</span>
              </Link>
            </p>
          </div>
        ))}
      </div>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    latest: allMarkdownRemark(
      limit: 1
      filter: { frontmatter: { date: { ne: null } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "DD MMMM, YYYY")
            slug
          }
          excerpt
        }
      }
    }
    all: allMarkdownRemark(
      skip: 1
      filter: { frontmatter: { date: { ne: null } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            description
            date(formatString: "DD MMMM, YYYY")
            slug
          }
          excerpt
        }
      }
    }
  }
`
